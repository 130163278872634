import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import './index.css'
import { Link, graphql} from 'gatsby'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES} from "@contentful/rich-text-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const IndexPage = (props) =>{

  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        return <a href={node.data.target.file.url} target="_blank" rel="noreferrer"  className="">{children}</a>
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        return <img src={node.data.target.file.url}  alt=""/>
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        return <Link href={`/exhibitions/${node.data.target.slug}`} >{children}</Link>
      }
  }
}

  const current = props.data.contentfulHomepage
  const galleryHours = current.galleryHours == null? "": renderRichText(current.galleryHours, options)
  const maininformation = current.mainInformation == null? "": renderRichText(current.mainInformation, options)

  const renderImage = () => {
    if(current.exhibitionLink == null){
      return <GatsbyImage image={getImage(current.mainImage)} loading="eager" alt=""/>
    }
    else{
      return  <Link to={`/exhibitions/${current.exhibitionLink.slug}`}>
                <GatsbyImage image={getImage(current.mainImage)} loading="eager" alt=""/>
              </Link>
    }

  }



return ( 
  <Layout>
    <SEO title="Home" />
      <h1 className="visibility-hidden">Homepage</h1>
      <div className='title-container'>
        {maininformation}
      </div>
      <div style={{ maxWidth: `100%`, marginBottom: `1.45rem`}}>
        {current.mainImage == null? "": 
          renderImage()
        }
        <div>
          <div className='galleryHours'>{galleryHours}</div>
        </div>
      </div>
  </Layout>
)
}

export default IndexPage

export const pageQuery = graphql
`
query homepagequery {
  contentfulHomepage(contentful_id: {eq: "7wAZhT0UBDtHEeOVktJsRA"}) {
    exhibitionLink {
      slug
    }
    mainInformation{
      raw
      references {
        ... on ContentfulExhibition {
          # contentful_id is required to resolve the references
          contentful_id
          title
          slug
        }
      } 
    }
    galleryHours {
      raw
      references {
        ... on ContentfulExhibition {
          # contentful_id is required to resolve the references
          contentful_id
          title
          slug
        }
      } 
    }
    mainImage {
      description
      gatsbyImageData(
        quality: 60
        width:900
        )
    }
  }
}
`
